<template>
  <van-action-sheet :round="false" style="background-color: #1c172a">
    <div class="header">
      <img src="../imgs/comm/pic_tc_zs.png" class="img-zs" />
      <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="close" />
      <img src="../imgs/comm/pic_tc_title.png" class="img-title" />
      <span>看漫简介</span>
    </div>

    <div class="content">
      <div class="block"></div>
      <div>
        看漫App
        作为小明太极(湖北)国漫文化有限公司旗下最重要的二次元漫画阅读平台，主要面对Z时代用户群体，在行业中受到各大资深平台赞扬，曾荣获HMS全球应用创新大赛“最佳应用奖”、中国动漫金龙“最具实力组织单位奖”等多项知名奖项。<br /><br />
        作为二次元行业第一梯队平台，公司专注于开发制作原创漫画作品，团队拥有内容创作人员达400多人，签约作者更是已超过1000人，全版权独家作品3000多部，全平台在线各类作品总数50000多部。<br /><br />
        目前看漫APP平台累计用户数超6300万，根据Trustdata公布的2020年12月数据，看漫APP的月活跃人数位列动漫阅读应用前三。看漫APP拥有丰富的内容和IP，其中不乏《仙武帝尊》《一剑独尊》《逆天邪神》《风起苍岚》《穿越西元3000后》《凤逆天下》等顶级IP，部分作品更是曾斩获业内多类奖项。<br /><br />
        在未来，看漫App平台将秉承服务亿万用户的理念，以互联网为纽带，以打造优质漫画内容为己任，与所有动漫爱好者一起，为中国动漫产业的发展贡献力量。
      </div>
      <div class="block"></div>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  emits: ['close'],
  setup(props, ctx) {
    const close = () => {
      ctx.emit('close');
    };

    return {
      close,
      icon_tc_gb: require('../imgs/icon/icon_tc_gb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content {
    background-color: $bc;
    color: $fc;
    font-size: 14px;
    div {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
</style>
