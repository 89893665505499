<!-- 宠物发行抽签按钮 -->
<template>
  <div :class="ishome ? '' : 'wraper'">
    <div v-if="itemData.is_advance_buy == 1">
      <div class="good-btn good-btn-gray">
        <div class="comBtn">已购买</div>
      </div>
    </div>
    <!-- itemData.status 状态 1 上架，2 已售空 -->
    <div v-if="itemData.status == 1 && Number(itemData.is_advance_buy) !== 1">
      <!-- "status_type": 1,       -- 当前状态： 1 报名，2 抽签， 3 开售 -->
      <!-- "user_lots_info":      -- 我参与の情况 -->
      <!-- 未开始 -->
      <div class="good-btn good-btn-gray" v-if="itemData.lots_info.status_type == -1">
        <div class="comBtn">未开始</div>
      </div>

      <!-- 已结束 -->
      <div class="good-btn good-btn-gray" v-if="itemData.lots_info.status_type == -2">
        <div class="comBtn">已结束</div>
      </div>

      <!-- 报名抽签-->
      <div
        class="good-btn good-btn-blue"
        v-if="itemData.lots_info.status_type == 1 && !itemData.user_lots_info"
      >
        <div class="comBtn" @click.stop="apply">报名抽签</div>
      </div>

      <!-- 邀请好友助力-->
      <div
        class="good-btn good-btn-blue"
        v-else-if="itemData.lots_info.status_type == 1 && itemData.user_lots_info"
      >
        <div class="doubleRowBtn" @click.stop="getenrollinfoHandle">
          <div class="rowFirst">邀请好友助力</div>
          <div class="rowSecond">已抽签，邀请助力提升中签率</div>
        </div>
      </div>

      <!-- 已结束 -->
      <div
        class="good-btn good-btn-gray"
        v-else-if="
          (itemData.lots_info.status_type == 3 || itemData.lots_info.status_type == 2) &&
          itemData.user_lots_info &&
          itemData.user_lots_info.is_lots &&
          !itemData.user_lots_info.is_buy &&
          itemData.user_lots_info.lots_round !== 0 &&
          itemData.user_lots_info.lots_round !== itemData.lots_info.round_num
        "
      >
        <div class="comBtn">已结束</div>
      </div>

      <!-- 抽签中 -->
      <div
        class="good-btn good-btn-gray"
        v-else-if="
          itemData.lots_info.status_type == 2 &&
          itemData.user_lots_info &&
          !itemData.user_lots_info.is_buy
        "
      >
        <div class="comBtn">抽签中</div>
      </div>

      <!-- 未抽签  -->
      <div
        class="good-btn good-btn-gray"
        v-else-if="
          (itemData.lots_info.status_type == 2 || itemData.lots_info.status_type == 3) &&
          !itemData.user_lots_info
        "
      >
        <div class="comBtn">未抽签</div>
      </div>

      <!-- 未中签 -->
      <div
        class="good-btn good-btn-gray"
        v-else-if="
          itemData.lots_info.status_type == 3 &&
          itemData.user_lots_info &&
          !itemData.user_lots_info.is_lots
        "
      >
        <div class="comBtn">未中签</div>
      </div>

      <!-- 中签 -->
      <div
        class="good-btn good-btn-blue"
        v-else-if="
          itemData.lots_info.status_type == 3 &&
          itemData.user_lots_info &&
          itemData.user_lots_info.is_lots &&
          !itemData.user_lots_info.is_buy &&
          itemData.user_lots_info.lots_round == itemData.lots_info.round_num
        "
      >
        <div class="comBtn" @click="$emit('drawLuckyBug', itemData)">立即购买</div>
      </div>

      <!-- 已购买 -->
      <div
        class="good-btn good-btn-gray"
        v-else-if="
          (itemData.lots_info.status_type == 3 || itemData.lots_info.status_type == 2) &&
          itemData.user_lots_info &&
          itemData.user_lots_info.is_lots &&
          itemData.user_lots_info.is_buy
        "
      >
        <div class="comBtn">已购买</div>
      </div>
    </div>

    <!-- 已售罄 -->
    <div
      v-if="itemData.status == 2 && Number(itemData.is_advance_buy) !== 1"
      class="good-btn good-btn-gray"
    >
      已结束
    </div>

    <div v-if="itemData.status == 1 && Number(itemData.is_advance_buy) !== 1">
      <!-- 未开始 -->
      <div class="tips-red t-c" v-if="itemData.lots_info.status_type == -1">
        <!-- <div class="comBtn">未开始</div> -->
        报名抽签倒计时
        <!-- 162341 -->
        <van-count-down
          class="countDown"
          millisecond
          format="HH:mm:ss"
          :time="diffTime(itemData.lots_info.start_time)"
          @finish="finistTime"
        />
      </div>

      <!-- 邀请好友助力提示 -->
      <div
        v-if="itemData.lots_info.status_type == 1 && itemData.user_lots_info"
        class="tips-red t-c"
      >
        {{ getTime(itemData.lots_info.next_round.end_time) }}公布抽签结果并开售
      </div>
      <!-- 抽签中 -->
      <div
        v-if="
          itemData.lots_info.status_type == 2 &&
          itemData.user_lots_info &&
          !itemData.user_lots_info.is_buy
        "
        class="tips-red t-c"
      >
        {{
          getTime(itemData.lots_info.next_round.start_time, 'HH:mm')
        }}公布抽签结果，中签即可立即购买
      </div>
      <!-- 未中签 -->
      <div
        v-if="
          itemData.lots_info.status_type == 3 &&
          itemData.user_lots_info &&
          !itemData.user_lots_info.is_lots &&
          itemData.lots_info.next_round
        "
        class="tips-red t-c"
      >
        {{ getTime(itemData.lots_info.next_round.start_time) }}可能还有抢购机会，请注意查收短信
      </div>
      <!-- 立即购买 -->
      <div
        v-if="
          itemData.lots_info.status_type == 3 &&
          itemData.user_lots_info &&
          itemData.user_lots_info.is_lots &&
          !itemData.user_lots_info.is_buy &&
          itemData.user_lots_info.lots_round == itemData.lots_info.round_num
        "
        class="tips-red t-c"
      >
        结束售卖倒计时
        <van-count-down
          class="countDown"
          millisecond
          format="HH:mm:ss"
          :time="diffTime(itemData.lots_info.now_round.end_time)"
          @finish="finistTime"
        />
        <!-- {{
          diffTime(itemData.lots_info.now_round.end_time) > 4
            ? '限时限量，请尽快购买哦~'
            : getTime(itemData.lots_info.now_round.end_time) + '结束售卖，请尽快购买哦~'
        }} -->
      </div>
    </div>

    <!-- 邀请助力 -->
    <van-popup
      class="vantPop"
      v-model:show="invitePop"
      closeable
      :close-on-click-overlay="false"
      close-icon-position="top-right"
    >
      <div class="helpPopBox">
        <div class="helpPop">
          <div class="title">已报名</div>
          <div class="time">{{ getTime(publish_time) }}公布抽签结果</div>
          <div class="amount">邀请好友助力，提升中签率，可邀请3人</div>
          <div class="rate">
            <div>中奖概率</div>
            <div class="progressBox">
              <img class="progressImg" :src="progressChange(helpNum)" />
              <div class="helpNum">
                <span>{{ helpNum }}人</span>已助力
              </div>
            </div>
          </div>
          <div class="helpBtn" @click.stop="inviteHelpHandle">邀请助力</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, watch, onMounted, inject } from 'vue';
import { luckydraw, getenrollinfo, invitehelp } from '@/service/home';
import { nftUtils, localStorageUtil } from '@/utils';
import useClipboard from 'vue-clipboard3';
import moment from 'moment';
import { useRouter } from 'vue-router';

export default {
  props: {
    itemData: {
      type: Object,
      default: {}
    },
    collections_id: {
      type: Number,
      default: 0
    }
  },
  emits: ['getList'],
  setup(props, ctx) {
    const toastFn = inject('$toast');
    const router = useRouter();
    const { toClipboard } = useClipboard();
    const state = reactive({
      invitePop: false,
      helpNum: 2,
      publish_time: null,
      ishome: false,
      isApp: nftUtils.isInApp()
    });
    onMounted(async () => {
      state.ishome = location.href.indexOf('/home') != -1;
    });

    // // 对比时间是否超过4小时
    // const diffTime = time => {
    //   return moment(time).diff(
    //     moment(Number(moment(Number(localStorageUtil.get('server_time'))).format('x'))),
    //     'hours'
    //   );
    // };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(Number(localStorageUtil.get('server_time')), 'millisecond');
    };

    // 报名抽签
    const apply = () => {
      console.log('邀请主力弹窗');
      luckydraw({
        collections_id: props.collections_id
      }).then(res => {
        console.log(res);
        if (res.status == 0) {
          getenrollinfoHandle();
          ctx.emit('getList');
        } else if (res.status == 2) {
          router.push({ path: '/authentication' });
        } else {
          toastFn({ message: res.message });
        }
      });
    };

    const finistTime = () => {
      ctx.emit('getList');
    };

    // 打开邀请助力弹窗
    const getenrollinfoHandle = () => {
      getenrollinfo({
        collections_id: props.collections_id
      }).then(res => {
        console.log(res);
        if (res.status == 0) {
          state.helpNum = res.data.help_num;
          state.publish_time = res.data.publish_time;
          state.invitePop = true;
        }
      });
    };

    // 获取邀请助力需要携带的参数
    const inviteHelpHandle = async () => {
      invitehelp({
        collections_id: props.collections_id
      }).then(res => {
        console.log(
          location.origin + `/helpPage?mid=${res.data.my_id}&cid=${res.data.collections_id}`
        );
        var shareInfo = res.data.share;
        if (state.isApp) {
          const shareConf = {
            title: shareInfo.share_title,
            content: shareInfo.share_content,
            image: shareInfo.share_image,
            link: shareInfo.share_link // app分享链接
          };

          nftUtils.shareDialog(shareConf);
        } else {
          try {
            // toClipboard(
            //   '看漫数藏|《诛天万域》天尊抽签开始，帮我助力增加中签概率,' +
            //     location.origin +
            //     `/helpPage?mid=${res.data.my_id}&cid=${res.data.collections_id}`
            // );
            toClipboard(
              shareInfo.share_title + ',' + shareInfo.share_content + ',' + shareInfo.share_link
            );
            toastFn({ message: '复制成功，请去分享吧～' });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    };

    const progressChange = num => {
      let src = '';
      switch (num) {
        case 0:
          src = require('../imgs/progress/progress_0.png');
          break;
        case 1:
          src = require('../imgs/progress/progress_1.png');
          break;
        case 2:
          src = require('../imgs/progress/progress_2.png');
          break;
        case 3:
          src = require('../imgs/progress/progress_3.png');
          break;
        default:
          src = require('../imgs/progress/progress_0.png');
      }
      return src;
    };

    // 获取格式时间
    const getTime = (time, format = 'MM月DD日 HH:mm') => {
      return moment(time).format(format);
    };

    return {
      ...toRefs(state),
      diffTime,
      getenrollinfoHandle,
      inviteHelpHandle,
      apply,
      progressChange,
      getTime,
      finistTime
    };
  }
};
</script>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
</style>

<style lang="scss" scoped>
@import '../styles/mixin.scss';

.countDown {
  display: inline-block;
  color: white;
  // color: #3bdbe8;
  margin-left: 2px;
}
.wraper {
  background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);
  padding-bottom: 19px;
  width: 100%;
}
.t-c {
  text-align: center;
}
.tips-red {
  font-size: 12px;
  font-weight: 500;
  color: #e63f3f;
  margin-top: 7px;
  // width: 100vw;
}
.good-btn {
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 272px;
  height: 45px;
  justify-content: center;
  align-items: center;

  .comBtn {
    width: 100%;
    line-height: 45px;
    text-align: center;
  }

  .doubleRowBtn {
    width: 100%;
    text-align: center;

    .rowFirst {
      font-size: 16px;
      font-weight: 600;
      color: #1c172a;
    }

    .rowSecond {
      font-size: 10px;
      font-weight: 300;
      color: #333333;
      line-height: 16px;
      transform: scale(0.9);
    }
  }
}
.good-btn-gray {
  background-image: url('../imgs/comm/pic_button_cmh_2.png');
  background-size: 100%;
  color: $fc;
}
.good-btn-blue {
  background-image: url('../imgs/comm/pic_button_cmh.png');
  background-size: 100%;
  color: $primaryc;
}
.helpPopBox {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
}

.helpPop {
  padding: 20px 25px 40px 25px;
  text-align: center;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
  }

  .time {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.7);
  }

  .amount {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
  }

  .rate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 38px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;

    .progressBox {
      position: relative;
      margin-left: 10px;
      font-size: 0;

      .helpNum {
        position: absolute;
        top: 100%;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;

        span {
          color: #3cdbe8;
        }
      }
    }
    .progressImg {
      width: 180px;
    }
  }

  .helpBtn {
    width: 116px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 auto;
    margin-top: 60px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 8px 8px 8px 8px;
    font-size: 16px;
    font-weight: 600;
    color: #1c172a;
  }
}
</style>
